$color-green: #52c41a;
$color-orange: #fa8c16;
$color-volcano: #fa541c;
$color-red: #f5222d;
$color-gray: #595959;
$color-dark: #262626;
$color-blue: #1890ff;
$color-cyan: #13c2c2;
$color-geekblue: #2f54eb;
$color-purple: #722ed1;
$color-magenta: #eb2f96;
$color-secondary: #8c8c8c;

$color-blue-light: #e6f7ff;
$color-gray-light: #d9d9d9;
$color-orange-light: #fff2e8;

$color-blue-dark: #003a8c;
$color-red-dark: #820014;

// Mixins

@mixin getShadowBox($color) {
  background: $color !important;
  color: #ffffff !important;

  box-shadow: rgba($color, 0.2) 0 10px 15px -3px,
  rgba($color, 0.1) 0 4px 6px -2px !important;

  &-light {
    color: $color;
    border: 1px solid rgba($color, 0.12);
    background: rgba($color, 0.1);
    display: inline-flex;
    align-items: center;
    min-height: 24px;

    &.round {
      color: #8c8c8c;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -8px !important;
      height: 32px;
      width: 32px !important;
      padding: 0;
      border-radius: 50%;
      background: #fafafa;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.tag-button {
    box-shadow: none !important;

    &:hover {
      background-color: darken($color, 10%) !important;
    }

    &.disabled {
      background-color: $color !important;
      cursor: not-allowed;
    }
  }

  &.outlined {
    border: 2px solid rgba($color, 0.8) !important;
    color: $color !important;
    background: none !important;
    box-shadow: none !important;

    &:hover {
      color: #fff !important;
      //border: none !important;
      background: $color !important;
    }
  }
}

.timeline-clock-icon {
  font-size: 16px;
  color: $color-red;
}

[data-theme='compact'] .timeline-clock-icon {
  font-size: 14px;
}


hr {
  margin: 30px 0;
  border-color: $color-gray-light;
  transition: all 0.3s ease-out;
}

strong.strong {
  font-weight: 500;
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-gray;
  height: fit-content;

  &.header-secondary {
    color: #8c8c8c;
  }
}

h1.header-page {
  font-size: 2.8rem;
  margin-bottom: 40px;
}

h2.header-primary {
  font-size: 2.6rem;
  margin-bottom: 0;
}

h3.header-primary {
  font-size: 2rem;
  margin-bottom: 30px;
}

h4.header-primary {
  font-size: 1.6rem;
  margin-bottom: 0;
}

h5.header-primary {
  font-size: 1.4rem;
  margin-bottom: 0;
}


.p-primary {
  line-height: 2.4rem !important;
  color: $color-gray !important;
  font-size: 1.6rem !important;
  margin-bottom: 30px;
}

.p-secondary {
  line-height: 2rem !important;
  color: $color-gray !important;
  font-size: 1.4rem !important;
  font-weight: 400;
  margin-bottom: 0;
}

.accent {
  color: $color-red;
  font-size: 2rem;
  font-weight: 400;
  text-shadow: 0 10px 15px rgba(245, 34, 45, 0.2),
  0 4px 6px rgba(245, 34, 45, 0.1);
}

.text-tiny {
  font-weight: 300 !important;
}

// Animation
.add-animation {
  transition: all 0.05s ease-in-out !important;

  &-01 {
    transition: all 0.1s ease-in-out !important;
  }

  &-02 {
    transition: all 0.2s ease-in-out !important;
  }

  &-03 {
    transition: all 0.3s ease-in-out !important;
  }
}

// Badge

.badge {
  &-blue,
  &-red,
  &-purple,
  &-orange {
    font-size: 1.5rem;
    font-weight: 500;
    display: inline-block;
    text-shadow: none;
    border-radius: 4px;
    padding: 2px 12px;
    color: #ffffff;
  }

  &-blue {
    @include getShadowBox($color-blue);
  }

  &-red {
    @include getShadowBox($color-red);
  }

  &-purple {
    @include getShadowBox($color-purple);
  }

  &-orange {
    @include getShadowBox($color-volcano);
  }
}

ul.list-primary {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    color: $color-gray;
    font-size: 1.6rem;
    padding: 3px 0;
  }
}

ul.list-secondary {
  list-style: none;
  padding-left: 20px;
  margin-bottom: 0;

  li {
    color: $color-gray;
    font-size: 1.6rem;
    padding: 3px 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -18px;
      top: 12px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 2px solid $color-blue-dark;
      //background: #fff;
    }
  }
}

.block-primary {
  background: $color-blue-light;
  padding: 15px 20px;
  border-radius: 8px;
}

.card {
  @mixin getBorder($color) {
    box-shadow: -3px 0 0 0 $color, 0px 2px 15px rgba(0, 0, 0, 0.05), rgba($color, 0.1) 0 2px 15px !important;
    background-color: mix(#fff, $color, 98%) !important;
    .ant-table-cell {
      background-color: mix(#fff, $color, 98%);
    }
  }

  border-radius: 6px !important;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05) !important;

  &.p-0 .ant-card-body {
    padding: 0 !important;
  }

  &.border-volcano {
    @include getBorder($color-volcano);
  }

  &.border-green {
    @include getBorder($color-green);
  }

  &.border-blue {
    @include getBorder($color-blue);
  }

  &.border-purple {
    @include getBorder($color-purple);
  }
}

// Buttons

svg.slow {
  transition: transform 0.5s cubic-bezier(.47, 1.64, .41, .8);
}

button.button {
  @mixin getButtonColor($color, $hover) {
    background-color: $color !important;

    &:hover {
      background-color: $hover !important;
    }

    &-outlined {
      color: $color !important;
      border-color: $color !important;
      background-color: rgba($color, 0.05) !important;

      &.dashed {
        border-style: dashed !important;
      }

      &:hover {
        color: #ffffff !important;
        background-color: $color !important;
        border-color: $color !important;
      }
    }

    &-link {
      border-radius: 4px;
      align-items: center !important;
      justify-content: center !important;
      display: inline-flex !important;
      color: $color !important;
      border: none !important;
      background-color: rgba($color, 0.05) !important;

      &.rounded {
        border-radius: 50% !important;
        min-width: 32px;
      }

      &:hover {
        color: rgba($color, 0.8) !important;
        background-color: rgba($color, 0.1) !important;
      }
    }
  }

  &-primary,
  &-secondary,
  &-warning,
  &-danger,
  &-primary-outlined,
  &-secondary-outlined,
  &-warning-outlined,
  &-danger-outlined {
    align-items: center !important;
    justify-content: center !important;
    display: inline-flex !important;
    color: #ffffff !important;
    border-color: transparent !important;
    font-weight: 400;
    border-radius: 4px;

    &.rounded {
      border-radius: 50% !important;
    }

    span {
      line-height: 1.5;
      padding-left: 2px;
    }

    &[disabled],
    &[disabled]:hover {
      color: #bfbfbfff !important;
      border-color: #bfbfbfff !important;
      background: #f5f5f5 !important;
    }

    &-outlined:hover {
      color: #ffffff !important;
    }

    &.button-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 32px;
      width: 32px;
    }
  }

  &-primary {
    @include getButtonColor(#1890ff, #096dd9);
  }

  &-secondary {
    @include getButtonColor(#8c8c8c, #096dd9);
  }

  &-warning {
    @include getButtonColor(#fa8c16, #d46b08);
  }

  &-access {
    @include getButtonColor(#52c41a, #389e0d);
  }

  &-danger {
    @include getButtonColor(#f5222d, #cf1322);
  }
}

.p-important {
  color: #5c0011;
  padding: 16px;
  margin-bottom: 0;
  background: #fff1f0;
  border-radius: 4px;
  border: 1px solid #ffa39e;
}

.dot-step {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2.4rem;
  margin-bottom: 0;
  color: #ffffff;

  @include getShadowBox($color-blue);
}

.text {
  &-dark-blue {
    color: $color-blue-dark !important;
  }

  &-secondary {
    color: $color-secondary !important;
  }

  &-primary {
    color: $color-gray !important;
  }

  &-red {
    color: $color-red !important;
  }

  &-volcano {
    color: $color-volcano !important;
  }

  &-orange {
    color: $color-orange !important;
  }

  &-blue {
    color: $color-blue !important;
  }

  &-green {
    color: $color-green !important;
  }

  &-purple {
    color: $color-purple !important;
  }


  &-weight {
    &-normal {
      font-weight: 400;
    }
  }

  &-size {
    &-1 {
      font-size: 0.5rem;
    }

    &-2 {
      font-size: 0.75rem;
    }

    &-3 {
      font-size: 1rem;
    }

    &-4 {
      font-size: 1.2rem;
    }

    &-5 {
      font-size: 1.5rem;
    }
  }
}

.ant-timeline-item-tail {
  border-left: 1px solid #f0f0f0;
}

.sub_timeline {
  .ant-timeline-item-head {
    top: 16px !important;
    color: $color-blue;
  }

  .ant-timeline-item-tail {
    top: 26px !important;
  }
}

// Restyling

.userPanel {
  display: flex;
  padding: 30px 10px 25px 25px;
  border-bottom: 1px solid #e8e8e8;

  &-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  &-link {
    margin: 10px;
    display: contents;
  }

  &-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.25;
    cursor: pointer;
    margin-left: 15px;
    color: #444444;
    display: flex;
    align-items: center;
    max-width: 125px;
  }
}

// Header dropdown

.header-dropdown {
  &-wrapper {
    border-left: 1px solid rgba($color-gray, 0.1);
    padding: 0 15px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $color-blue-light;
    }
  }

  &-item {
    padding: 5px 12px 5px 10px !important;
    border-bottom: 1px solid rgba($color-gray, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    min-height: 40px;
    border-left: 3px solid transparent;
    color: $color-gray;
    transition: all 0.3s ease !important;

    &.item-red {
      &:hover {
        color: $color-red;
        border-left: 3px solid $color-red;
      }
    }

    &:hover {
      border-left: 3px solid $color-blue;
      color: $color-blue;
    }
  }
}

// Tags

.tag {
  &-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    width: 24px !important;
    height: 24px;
  }

  &-dark {
    @include getShadowBox($color-dark)
  }

  &-gray,
  &-default {
    @include getShadowBox($color-gray)
  }

  &-snow {
    @include getShadowBox(#bfbfbf)
  }

  &-red,
  &-group {
    @include getShadowBox($color-red)
  }

  &-volcano {
    @include getShadowBox($color-volcano)
  }

  &-orange,
  &-service {
    @include getShadowBox($color-orange)
  }

  &-green {
    @include getShadowBox($color-green)
  }

  &-blue,
  &-classic_user {
    @include getShadowBox($color-blue)
  }

  &-geekblue,
  &-user {
    @include getShadowBox($color-geekblue)
  }

  &-purple {
    @include getShadowBox($color-purple)
  }

  &-magenta {
    @include getShadowBox($color-magenta)
  }

  &-cyan,
  &-phantom {
    @include getShadowBox($color-cyan)
  }
}

.color-switcher {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 32px !important;
  height: 32px !important;
  cursor: pointer;
  background-color: rgba($color-blue, 0);
  border: 1px solid rgba($color-blue, 0);
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &.checked {
    background-color: rgba($color-blue, 0.15);
    border-color: rgba($color-blue, 0.3);
  }
}

// Table

.perms {
  @mixin getTableColor($color) {
    .ant-table-wrapper {
      box-shadow: rgba($color, 0.1) 0 10px 15px -3px,
      rgba($color, 0.05) 0 4px 6px -2px;
    }

    .perms-title {
      margin: 0;
      padding: 12px 16px;
      background-color: rgba($color, 0.2);
      font-size: 2rem;
      border-radius: 4px 4px 0 0;
    }

    thead th {
      background-color: rgba($color, 0.07) !important;
      border-top: 1px solid rgba($color, 0.15) !important;

      &:nth-last-child(1) {
        border-right: 1px solid rgba($color, 0.15) !important;
      }

      &:nth-child(-n+2) {
        border-left: 1px solid rgba($color, 0.15) !important;
      }
    }

    tr {
      background-color: rgba($color, 0.06) !important;

      &:nth-child(odd) td {
        background-color: rgba($color, 0.02) !important;
      }

      td {
        border-top: 1px solid rgba($color, 0.15) !important;

        &:nth-last-child(1) {
          border-right: 1px solid rgba($color, 0.15) !important;
        }

        &:nth-child(-n+2) {
          border-left: 1px solid rgba($color, 0.15) !important;
        }
      }

      &:nth-last-child(1) td {
        border-bottom: 1px solid rgba($color, 0.15) !important;
      }

      &:hover > td {
        background-color: transparent !important;
      }

      &.ant-table-row-selected > td {
        background-color: #e6f7ff !important;
      }
    }

    .ant-empty {
      margin: 0 !important;
    }
  }

  &-actor {
    @include getTableColor($color-blue);
  }

  &-group {
    @include getTableColor($color-volcano)
  }

  &-default {
    @include getTableColor($color-green);
  }

  &-admin {
    @include getTableColor($color-purple);
  }
}

.bg {
  @mixin getBgStyle($color) {
    background-color: rgba($color, 0.06);
    border: 1px solid rgba($color, 0.08);
    color: $color;
  }

  &-green {
    @include getBgStyle($color-green)
  }

  &-red {
    @include getBgStyle($color-red)
  }

  &-secondary {
    @include getBgStyle($color-blue-light)
  }
}

label.checkbox {
  &-primary,
  &-access {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    margin: 0;

    .ant-checkbox-inner::after {
      top: 8px;
      left: 4px;
    }

    input,
    .ant-checkbox,
    .ant-checkbox-inner,
    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
      height: 18px !important;
      width: 18px !important;
    }
  }
}

// Search
.custom-search-field {
  width: 100%;
  max-width: 350px;
  font-size: 14px;
  color: #242424;
  padding-left: 40px;
  height: 32px;
}

// Info block
.info-block {
  padding-top: 16px;

  &-icon,
  &-header {
    color: #8c8c8c
  }

  &-header {
    margin-bottom: 10px;
  }
}

// Tags setting list

.areas_filter {
  @mixin getFilterColor($color) {
    background-color: rgba($color, 0.05);
    border-color: rgba($color, 0.3);
    transition: all 0.3s ease-in-out;

    input[type='checkbox']:checked {
      background-color: $color;
    }

    &.active {
      color: $color;
      border-color: $color;
      background-color: rgba($color, 0.1);
    }

    &:hover {
      background-color: rgba($color, 0.075);
      border-color: rgba($color, 0.6);
    }
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    user-select: none;
    color: $color-gray;

    .isGroups {
      label {
        @include getFilterColor($color-gray)
      }
    }

    .filter-red label {
      @include getFilterColor($color-red)
    }

    .filter-blue label {
      @include getFilterColor($color-blue)
    }

    .filter-gray label {
      @include getFilterColor($color-gray)
    }

    label {
      &.ADMIN {
        @include getFilterColor($color-volcano)
      }

      &.DEFAULT {
        @include getFilterColor($color-blue)
      }

      &.BAN {
        @include getFilterColor($color-red)
      }

      &.ROOT {
        @include getFilterColor($color-purple)
      }
    }

    .rowText-line {
      height: 1px;
      background-color: rgba($color-gray-light, 0.4);
    }

    &.isSmall {
      label {
        padding: 2px 8px;
        height: 26px;
        font-size: 14px;
      }

      input[type='checkbox']:checked {
        height: 20px;
        width: 20px;
        margin-right: 4px;
        margin-left: -4px;
      }

      svg {
        left: 4px;
      }
    }
  }

  &-container,
  &-group {
    display: flex;

    input[type='checkbox'] {
      width: 0;
      height: 0;
      border-radius: 50%;
      outline: none;
      -webkit-appearance: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:checked {
        height: 26px;
        width: 26px;
        background-color: $color-blue;
        margin: 0 8px 0 0;
      }
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      height: 36px;
      border: 1px solid rgba($color-gray, 0.3);
      border-radius: 18px;
      margin: 4px;
      cursor: pointer;
      padding: 2px 12px 2px 6px;
      background-color: rgba($color-gray, 0.05);
      transition: all 0.3s ease-in-out;

      &.active {
        color: $color-blue;
        border-color: $color-blue;
        background-color: rgba($color-blue, 0.1);
      }

      &:hover {
        background-color: rgba($color-blue, 0.075);
        border-color: rgba($color-blue, 0.6);
      }
    }

    svg {
      position: absolute;
      left: 7px;
      color: #ffffff;
    }
  }

  &-group {
    margin: 4px;

    label {
      margin: 0;
      border-radius: 0;

      &:nth-child(1) {
        border-radius: 18px 0 0 18px;
      }

      &:nth-last-child(1) {
        border-radius: 0 18px 18px 0;
      }
    }
  }

  &-excluded {
    display: flex;
    cursor: pointer;
    margin: 4px;

    div {
      display: flex;
      height: 36px;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      transition: all 0.15s ease-in-out;
    }

    div:nth-child(1) {
      border-top: 1px solid rgba($color-gray, 0.3);
      border-left: 1px solid rgba($color-gray, 0.3);
      border-bottom: 1px solid rgba($color-gray, 0.3);
      border-radius: 18px 0 0 18px;
      background-color: rgba($color-gray, 0.05);
    }

    div:nth-child(2) {
      border: 1px solid rgba($color-gray, 0.3);
      background-color: rgba($color-gray, 0.05);
    }

    div:nth-child(3) {
      border-top: 1px solid rgba($color-gray, 0.3);
      border-right: 1px solid rgba($color-gray, 0.3);
      border-bottom: 1px solid rgba($color-gray, 0.3);
      border-radius: 0 18px 18px 0;
      background-color: rgba($color-gray, 0.05);
    }

    &.isSmall {
      height: 26px;

      div {
        padding: 2px 6px;
        min-width: 26px;
        height: 26px;
        font-size: 14px;
      }

      div:nth-child(1) {
        padding: 0;
        border-radius: 13px 0 0 13px;
      }

      div:nth-child(3) {
        padding: 0;
        border-radius: 0 13px 13px 0;
      }
    }

    &.included {
      div:nth-child(1) {
        background-color: #52c41a;
        color: #ffffff;
        border-color: #52c41a;
      }

      div:nth-child(2) {
        background-color: rgba($color-gray, 0.05);
        color: $color-green;
        border-color: $color-green;
      }
    }

    &.excluded {
      div:nth-child(3) {
        background-color: $color-red;
        color: #ffffff;
        border-color: $color-red;
      }

      div:nth-child(2) {
        background-color: #fff1f0;
        color: $color-red;
        border-color: $color-red;
      }
    }
  }
}

// Tabs

.tabs-left {
  .ant-tabs-tab {
    padding: 8px !important;
    margin: 0 !important;
    transition: background-color 0.3s ease;

    &:hover {
      color: $color-blue !important;
      background-color: $color-blue-light;
    }

    &-active {
      background-color: $color-blue-light;

      .ant-tabs-tab-btn {
        color: $color-blue !important;
      }
    }
  }
}

// Border

.add-border {
  @mixin getBorderColor($color) {
    background-color: rgba($color, 0.1);
    border-radius: 4px;
    border: 1px solid rgba($color, 0.3);
  }

  &-blue {
    @include getBorderColor($color-blue)
  }

  &-green {
    @include getBorderColor($color-green)
  }

  &-red {
    @include getBorderColor($color-red)
  }
}

// Radio

.radio-item {
  @mixin getRadioColor($color) {
    background-color: rgba($color, 0.06);
    color: $color;
    border-color: $color !important;

    &:hover {
      color: $color;
      background-color: rgba($color, 0.1);
    }

    &.active {
      @include getShadowBox($color)
    }

    svg.slow {
      transition: transform 0.5s cubic-bezier(.47, 1.64, .41, .8);
    }
  }

  border-radius: 4px !important;
  margin-right: 4px;
  border: 1px solid;

  &::before {
    display: none !important;
  }

  &-blue {
    @include getRadioColor($color-blue)
  }

  &-geekblue {
    @include getRadioColor($color-geekblue)
  }

  &-red {
    @include getRadioColor($color-red)
  }

  &-volcano {
    @include getRadioColor($color-volcano)
  }

  &-orange {
    @include getRadioColor($color-orange)
  }
}

// Fix MD Editor

.w-md-editor-aree.w-md-editor-input {
  min-height: 300px !important;

  textarea {
    min-height: 300px !important;
  }
}

.infoIconBth {
  color: $color-blue;
  cursor: pointer;

  // &:hover{
  // 	// color:$color-geekblue;
  // 	color: inherit
  // }
}

.colorRed {
  color: #f5222d !important;
}

.InfoBtnContainer {
  cursor: pointer;
  color: #595959;

  &:hover {
    color: $color-geekblue !important;
  }
}

@mixin getShadow($color) {
  filter: drop-shadow(0 10px 15px rgba($color, 0.2)) drop-shadow(0 4px 6px rgba($color, 0.1));
}

.icon-shadow {
  &-dark {
    @include getShadow($color-dark)
  }

  &-gray {
    @include getShadow($color-gray)
  }

  &-red {
    @include getShadow($color-red)
  }

  &-volcano {
    @include getShadow($color-volcano)
  }

  &-orange {
    @include getShadow($color-orange)
  }

  &-green {
    @include getShadow($color-green)
  }

  &-cyan {
    @include getShadow($color-cyan)
  }

  &-blue {
    @include getShadow($color-blue)
  }

  &-geekblue {
    @include getShadow($color-geekblue)
  }

  &-purple {
    @include getShadow($color-purple)
  }
}

.icon-switch {
  height: 32px !important;
  width: 32px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba($color-blue, 0);
  border: 1px solid rgba($color-blue, 0);
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &.checked {
    background-color: rgba($color-blue, 0.15);
    border-color: rgba($color-blue, 0.3);
  }
}

a {
  h3, h5 {
    transition: all 0.3s ease;
    //color: $color-gray;
  }

  &:hover {
    h3, h5 {
      color: $color-blue;
    }
  }
}

.avatar-user {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.4rem;

  p {
    margin: 0;
  }
}

.switch-primary {
  border-radius: 4px !important;
  min-width: 54px;

  &.ant-switch-checked {
    background-color: $color-green;
  }

  .ant-switch-handle::before {
    border-radius: 2px !important;
  }
}

div.card-action {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  width: 100%;
  background-color: $color-blue-light;
  border-radius: 0 0 6px 6px;
  box-shadow: $color-blue 0 3px 0 0;
  color: $color-blue;
  font-size: 1.8rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: $color-blue;
    color: #fff;
  }
}

// Collapse
.card-collapse {
  position: relative;

  &.ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-header {
    display: none !important;

    .ant-collapse-arrow {
      transition: all 0.3s ease-out;
      margin: 0 8px 0 0 !important;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.row {
  @mixin getColor($color) {
    border-top: 1px solid rgba($color, 0.1) !important;

    &:nth-child(odd) td {
      background-color: rgba($color, 0.05) !important;
    }

    td {
      background-color: rgba($color, 0.05) !important;
      border-top: 1px solid rgba($color, 0.1) !important;
      border-bottom: 1px solid rgba($color, 0.1) !important;
      //color: $color;

      &:first-child {
        border-left: 1px solid rgba($color, 0.1) !important;
      }

      &:last-child {
        border-right: 1px solid rgba($color, 0.1) !important;
      }
    }
  }

  &-error {
    @include getColor($color-red)
  }
}
