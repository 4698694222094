.ant-tag {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 7px;
  margin: 0;
  font-weight: 400;
  border: none;
  width: fit-content;
  height: fit-content;
}

.ant-select-clear {
  top: 44%;
}

.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center;
}

.ant-switch-inner {
  display: flex !important;
}

.ant-table-selection-column {
  display: none;
}

.ant-pagination-item-active a {
  pointer-events: none;
}

.disable-sort .ant-table-column-sort {
  background-color: transparent !important;
}

.ant-table-row:nth-child(odd) td {
  background-color: #fafafa !important;
}

.table-selectable {
  &.ant-table-row {
    cursor: pointer;

    td {
      color: #595959 !important;
      //background-color: transparent !important;
      transition: all 0.3s ease !important;
    }

    &-selected {
      box-shadow: 2px 0 #1890ff !important;
      color: #1890ff !important;

      td {
        color: #1890ff !important;
        background-color: #e6f7ff !important;
      }
    }

    &:hover {
      &.ant-table-row-selected > td {
        background-color: #e6f7ff !important;
      }

      td {
        color: #1890ff !important;
        background-color: rgba(#e6f7ff, 0.5) !important;
      }
    }
  }
}

button.ant-pagination-item {
  &-link {
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.ant-ribbon {
  pointer-events: none;
}

.table-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px solid #1890ff !important;
}

.table-drag-sorting tr.drop-over-upward td {
  border-top: 2px solid #1890ff !important;
}

.ant-input[disabled],
.ant-select-disabled.ant-select-multiple .ant-select-selection-item,
.ant-input-number-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.5);
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 2px;
}

.ant-form-item-label > label {
  color: #8c8c8c;
  font-size: 1.25rem;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  padding-top: 4px;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-checkbox-wrapper {
  color: #8c8c8c;
  font-size: 1.25rem;
}

.ant-breadcrumb {
  font-size: 2.8rem;
  padding-bottom: 40px;
  line-height: normal;

  .ant-breadcrumb-link {
    color: #595959;

    a {
      color: rgba(0, 0, 0, 0.45);

      &:hover {
        color: #40a9ff;
      }
    }
  }
}

ul.ant-card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  border-radius: 0 0 6px 6px;

  li,
  span {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
  }
}

.ant-tooltip-disabled-compatible-wrapper {
  padding: 0 !important;
}

button:focus {
  outline: none !important;
}
