.form-control {
  display: block;
  width: 100%;
  height: calc(3.42rem + 2px);
  padding: 0.8rem 1.7rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.3;
  color: #545454;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-text {
  padding: 0 11px;
  background: #f5f5f5;
}

.input-group-text i {
  font-size: 18px;
}

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 10px 0 !important;
  font-size: 12px !important;
  line-height: 1.42;
  border-radius: 50%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  font-size: 15px;
}

.btn-sm {
  font-size: 14px;
  padding: 3px 10px;
}

.btn i {
  margin-right: 5px;
  font-size: 18px;
}

.btn.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}

.btn.btn-primary i {
  font-size: 20px;
  margin-right: 5px;
}

.btn-add-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  height: 25px;
  border-radius: 5px !important;
}

.btn-add-action i {
  font-size: 18px;
  line-height: 1;
}

.btn-add-action + .dropdown-menu {
  min-width: 200px;
}

.btn-add-action + .dropdown-menu span {
  display: flex;
  padding: 10px;
}
