@import 'sidebar.css';
@import 'forms.css';
@import 'tables.css';
@import 'navs.css';
@import '../../node_modules/@mdi/font/css/materialdesignicons.css';

body {
  background: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1.8rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: #262626;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1,
.h1 {
  font-size: 2.2rem;
}

.dt-page__header {
  margin-bottom: 25px;
}

th {
  outline: none;
}
